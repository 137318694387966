<template>
  <el-container class="hp-100">
    <el-main class="hp-100 bgimg">
      <div class="login hp-100">
        <div class="login_con">
          <div class="img" />
          <h1 style="font-weight: 500">菁锐艾媒科普后台管理系统</h1>
          <el-form :inline="true" label-width="78px" label-position="left">
            <el-form-item label="用户名：">
              <el-input placeholder="请输入用户名" v-model="form.phone" />
            </el-form-item>

            <el-form-item label="密码：">
              <el-input
                show-password
                placeholder="请输入密码"
                v-model="form.password"
              />
            </el-form-item>
            <!-- <el-form-item>
            <el-input v-model="code" >
              <template slot="append">获取验证码</template>
            </el-input>
            </el-form-item>-->
            <el-button class="btn" round @click="onSubmit" style="width: 100%"
              >登录</el-button
            >
          </el-form>
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import { login } from "@/api/login";
export default {
  name: "Login",
  data() {
    return {
      form: {
        phone: "", // 账号
        password: "", //密码
        // phone: "admin", // 账号
        // password: "system123", //密码
      },
    };
  },
  methods: {
    async onSubmit() {
      const { data: res } = await login(this.form);

      if (res.success) {
        this.$message.success("登录成功");
        console.log(res.data.menuTree);
        const token = res.data.Authorization;

        sessionStorage.setItem("token", token);

        sessionStorage.setItem("userId", res.data.userInfo.id);

        sessionStorage.setItem("userName", res.data.userInfo.phone);

        sessionStorage.setItem("menuTree", res.data.menuTree);

        this.$store.commit("login", res.data);

        this.$router.replace({
          path: "/",
        });
      } else {
        this.$messageError(res.message);
      }
    },
  },
};
</script>

<style lang="less" scope>
.el-container {
  .bgimg {
    background: url("../../../../static/images/login.png") no-repeat center;
    background-size: 100% 100%;
    padding: 30px !important;
    position: relative;
    .el-form-item {
      margin-right: 0 !important;
    }

    .el-form-item__content {
      width: 310px !important;
    }
  }
  .el-main {
    .login {
      &_con {
        width: 400px;
        position: absolute;
        top: 50%;
        left: 70%;
        transform: translate(-50%, -50%);
        .img {
          height: 180px;
          background: url("/static/image/login.png") no-repeat center;
        }
        h1 {
          margin: 50px 0;
          text-align: center;
          color: #151515;
          font-weight: 500;
          font-family: "PingFang SC";
        }
        h3 {
          color: #3370ff;
          font-size: 22px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>